<template>
    <v-container class="connect-container">
        <v-row>
            <v-col cols="12">
                <h1>Connect with Me</h1>
                <p>
                    Explore my work, get in touch or simply stay connected with me through the following platforms:
                </p>
                <v-row style="max-width: 300px; margin: 0 auto">
                    <v-col cols="12">
                        <v-icon left>mdi-email</v-icon>
                        <span>Email: info@digital-boop.co.uk</span>
                    </v-col>
                    <v-col cols="12" md="6">
                        <a href="https://www.linkedin.com/in/rebeccastill1/" target="_blank" rel="noopener noreferrer">
                            <v-icon left>mdi-linkedin</v-icon>
                            <span>LinkedIn</span>
                        </a>
                    </v-col>
                    <v-col cols="12" md="6">
                        <a href="https://github.com/Boopster/" target="_blank" rel="noopener noreferrer">
                            <v-icon left>mdi-github</v-icon>
                            <span>GitHub</span>
                        </a>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: "Connect",
};
</script>
<style scoped>
h1 {
    margin-bottom: 1rem;
}

.connect-container {
    max-width: 600px;
    margin: 128px auto 0;
}

.router-view {
    min-height: 80vh !important;
}
</style>




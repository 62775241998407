<template>
    <v-btn class="secondary-button" :to="to" :type="type" :href="href" :disabled="disabled" :style="buttonStyle" dark>
        <slot></slot>
    </v-btn>
</template>

<script>
export default {
    name: "SecondaryButton",
    props: {
        to: [String, Object],
        type: String,
        href: String,
        disabled: Boolean,
        backgroundColor: {
            type: String,
            default: "#ffffff",
        },
    },
    computed: {
        buttonStyle() {
            return {
                backgroundColor: this.backgroundColor,
            };
        },
    },
};
</script>

<style scoped>
.secondary-button.v-btn {
    border-radius: 40px !important;
    border: 1px solid #333 !important;
    padding: 16px 32px !important;
    box-shadow: none;
    text-transform: none;
    min-width: 203px !important;
}
.secondary-button.v-btn:not(.v-btn--disabled) {
    background-color: #fff !important;
    color: #333;
    height: 60px;
}
</style>



<template>
  <div id="app">
    <div class="app-container">
      <Header />
      <router-view class="router-view" />
      <Footer />
    </div>
  </div>
</template>

<script>
import Header from './components/Header.vue';
import Footer from './components/Footer.vue';

export default {
  name: 'App',
  components: {
    Header,
    Footer
  }
};
</script>

<style>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

body,
html {
  font-family: 'Pathway Extreme', sans-serif;
}

p {
  margin-bottom: 16px;
  line-height: 1.4;
}

/* Add the following styles */
.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.router-view {
  flex: 1;
}</style>


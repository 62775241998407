<template>
    <v-container fluid>
        <!-- Hero section -->
        <v-row align="center" justify="center" class="hero-section">
            <v-col cols="12">
                <h1 class="hero-title">Becky Still</h1>
                <p class="hero-subtitle">UX Designer & Developer</p>
                <PrimaryButton to="/projects">View My Projects</PrimaryButton>
            </v-col>
        </v-row>

        <!-- Content section -->
        <v-row>
            <v-col cols="12" style="padding: 32px 64px 64px; background-color: #f5f5f5;">
                <h2>Empowering Change through Design</h2>
                <h3 style="color: gray">Discover Becky Still's Creative World</h3>
                <p>
                    Welcome to the world of Becky Still, where creativity, passion, and technical expertise come together to empower change through design. Get to know me and discover how I use my skills in UX design and development to create meaningful digital experiences that make a difference. Dive into my story and see how I bring technology to life for the greater good.
                </p>
                <SecondaryButton to="/about">Read More</SecondaryButton>
            </v-col>
           <v-col cols="12" style="padding: 32px 64px 64px;">
                <h2>Coming Soon!  Latest Projects</h2>
                <h3 style="color: gray">Explore Becky Still's Latest Tech Projects</h3>
                <p>
                    Take a closer look at my most recent projects, where innovative design meets social impact. Each project showcases my commitment to creating engaging digital experiences that empower change and improve lives. Browse through these exciting collaborations and see how I've utilised my UX design and development expertise to contribute to a better future.
                </p>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import PrimaryButton from "./PrimaryButton.vue";
import SecondaryButton from "./SecondaryButton.vue";

export default {
    name: "Home",
    components: {
        PrimaryButton,
        SecondaryButton,
    },
};
</script>

<style scoped>
h2 {
    margin-top: 24px;
    margin-bottom: 0;
}
h3 {
    margin-bottom: 24px;
}
p {
    max-width: 600px;
    margin: 0 auto 32px;
    line-height: 1.4;
}
.hero-section {
    background-color: white;
    text-align: center;
    margin-bottom: 3rem;
}

.hero-title {
    font-size: 3rem;
    font-weight: bold;
    margin-bottom: 1rem;
    margin-top: 1rem;
}

.hero-subtitle {
    font-size: 1.5rem;
    margin-bottom: 2rem;
}
</style>

<!-- <SecondaryButton to="/projects">Coming Soon</SecondaryButton> -->


<template>
    <header>
        <nav class="navbar">
            <div class="container">
                <div class="logo">
                    <router-link to="/">Becky Still</router-link>
                </div>
                <ul class="nav-links">
                    <li><router-link to="/">Home</router-link></li>
                    <li><router-link to="/about">About Me</router-link></li>
                    <li><router-link to="/contact">Contact</router-link></li>
                    <li> <a href="https://www.linkedin.com/in/rebeccastill1/" target="_blank" rel="noopener noreferrer">
                                    <v-icon dark size="32" class="white--text">mdi-linkedin</v-icon>
                                </a></li>
                                <li> <a href="https://github.com/Boopster" target="_blank" rel="noopener noreferrer">
                                    <v-icon dark size="32" class="white--text">mdi-github</v-icon>
                                </a></li>
                </ul>
            </div>
        </nav>
    </header>
</template>
<script>
export default {
    name: 'Header',
};
</script>

<style scoped>
header {
    background-color: #333;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
}

.container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
    padding: 1rem;
}

.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.logo {
    font-size: 1.5rem;
    font-weight: bold;
}

.logo a,
.nav-links a {
    color: #fff;
    text-decoration: none;
    margin: auto;
}

.nav-links {
    display: flex;
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.nav-links li {
    margin: auto 0 auto 1rem;
}

.nav-links li:hover,
.nav-links li a:hover {
    color: #ccc;
}
</style>

<!-- <li><router-link to="/projects">Projects</router-link></li> -->

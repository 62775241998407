<template>
    <v-btn class="custom-button" :to="to" :type="type" :href="href" :disabled="disabled" :style="buttonStyle" dark>
        <slot></slot>
    </v-btn>
</template>

<script>
export default {
    name: "PrimaryButton",
    props: {
        to: [String, Object],
        type: String,
        href: String,
        disabled: Boolean,
        backgroundColor: {
            type: String,
            default: "#010101",
        },
    },
    computed: {
        buttonStyle() {
            return {
                backgroundColor: this.backgroundColor,
            };
        },
    },
};
</script>

<style scoped>
.custom-button.v-btn {
    border-radius: 40px !important;
    padding: 16px 32px !important;
    box-shadow: none;
    text-transform: none;
    font-weight: bold;
}

.custom-button.v-btn:not(.v-btn--disabled) {
    background-color: #333 !important;
    height: 60px;
}
</style>



<template>
    <div>
        <!-- Site map -->
        <div class="site-map">
            <v-container>
                <v-row>
                    <v-col cols="12" md="3">
                        <div class="logo">
                            <router-link to="/">
                                <span>Becky Still</span>
                                <span>UX Designer & Developer</span>
                            </router-link>
                        </div>
                    </v-col>
                    <v-col cols="12" md="3">
                        <h3>Main Pages</h3>
                        <ul class="nav-links">
                            <li><router-link to="/">Home</router-link></li>
                            <li><router-link to="/about">About</router-link></li>
                            <li><router-link to="/contact">Contact</router-link></li>
                        </ul>
                    </v-col>
                    <!-- New Policies Column -->
                    <v-col cols="12" md="3">
                        <h3>Policies</h3>
                        <ul class="nav-links">
                            <li><router-link to="/privacy-policy">Privacy Policy</router-link></li>
                            <li><router-link to="/accessibility-statement">Accessibility Statement</router-link></li>
                        </ul>
                    </v-col>
                    <!-- New Links Column -->
                    <v-col cols="12" md="3">
                        <h3>Links</h3>
                        <div class="icon-links">
                            <a href="https://www.linkedin.com/in/rebeccastill1/" target="_blank" rel="noopener noreferrer">
                                <v-icon dark size="32" class="white--text">mdi-linkedin</v-icon>
                            </a>
                            <a href="https://github.com/Boopster" target="_blank" rel="noopener noreferrer">
                                <v-icon dark size="32" class="white--text">mdi-github</v-icon>
                            </a>
                        </div>
                    </v-col>
                </v-row>
            </v-container>
        </div>

        <!-- Footer -->
        <v-footer padless>
            <v-card flat tile class="indigo darken-1 white--text container">
                <v-card-text>
                    &copy; {{ new Date().getFullYear() }} Becky Still - UX Designer &amp; Developer, All Rights Reserved
                </v-card-text>
            </v-card>
        </v-footer>
    </div>
</template>

<script>
export default {
    name: "Footer",
};
</script>

<style scoped>
/* Add this style for icon-links */
.icon-links {
  display: flex;
  justify-content: flex-start;
}
.icon-links a {
  margin-right: 0.5rem;
}
.container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
    padding: 1rem;
}
.site-map {
    background-color: #333;
    padding: 1rem 0;
}

.v-card {
    padding: 16px;
}

.logo {
    font-size: 1.5rem;
    font-weight: bold;
    text-align: left;
}
.logo span {
    display: block;
}
.logo a,
.nav-links a {
    color: #fff;
    text-decoration: none;
}

.nav-links {
    display: flex;
    flex-direction: column;
    list-style-type: none;
    padding: 0;
    margin: 0;
    text-align: left
}

.nav-links li {
    margin-bottom: 0.5rem;
}

.nav-links li:hover,
.nav-links li a:hover {
    color: #ccc;
}

h3 {
    color: #fff;
    margin-bottom: 0.5rem;
    text-align: left;
}
</style>

<!-- <li><router-link to="/projects">Projects</router-link></li> -->


<template>
    <v-container>
        <h1>Projects</h1>
        <v-row>
            <v-col v-for="project in projects" :key="project.id" cols="12" sm="6" md="4" lg="3">
                <v-card class="mx-auto" max-width="350">
                    <v-img :src="project.image" aspect-ratio="1.5" contain></v-img>
                    <v-card-title>{{ project.title }}</v-card-title>
                    <v-card-subtitle>{{ project.subtitle }}</v-card-subtitle>
                    <v-card-actions>
                        <v-btn text color="primary" :to="{ name: 'Project', params: { id: project.id } }">View
                            Details</v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: "Projects",
    data() {
        return {
            projects: [
                {
                    id: 1,
                    title: "Project 1",
                    subtitle: "Web Design",
                    image: "https://via.placeholder.com/350x200",
                },
                {
                    id: 2,
                    title: "Project 2",
                    subtitle: "Mobile App",
                    image: "https://via.placeholder.com/350x200",
                },
                {
                    id: 3,
                    title: "Project 3",
                    subtitle: "UI Design",
                    image: "https://via.placeholder.com/350x200",
                },
                {
                    id: 4,
                    title: "Project 4",
                    subtitle: "Web Development",
                    image: "https://via.placeholder.com/350x200",
                },
                // Add more projects here...
            ],
        };
    },
};
</script>

<style scoped>
h1 {
    margin-bottom: 1rem;
}

.v-card {
    margin-bottom: 2rem;
}
</style>

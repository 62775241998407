<template>
    <v-container class="about-me-container">
        <v-row>
            <v-col cols="12" md="6" class="image-column">
                <v-avatar size="400">
                    <v-img :src="require('@/assets/images/ProfileImage.jpg')" contain></v-img>
                </v-avatar>
            </v-col>
            <v-col cols="12" md="6" class="about-me">
                <h1>About Me</h1>
                <p>
                    Hello there! I'm Becky Still, a UX designer and developer based in Glasgow, Scotland, with over 3 years
                    of experience in the field. I'm passionate about creating digital experiences that are both intuitive
                    and engaging, catering to users' needs and expectations. My expertise spans across web design and mobile
                    app development, with a user-centered approach that focuses on understanding user behavior and
                    incorporating their feedback.</p>
                <p>

                    As a strong advocate for the #TechForGood movement, I actively volunteer with the Scottish Tech Army and
                    work with The Book Whisperers Community Interest Company. My experience with the Scottish Tech Army has
                    helped me expand my network, upskill in various areas, and contribute to impactful projects like the
                    COVID dashboard. In collaboration with other volunteers, we made COVID data accessible and
                    understandable for a wider audience, which was recognized by the Scottish Government.</p>
                <p>

                    Proficient in front-end technologies like Vue.js and React, I bring my designs to life through code.
                    When I'm not designing or coding, I enjoy spending quality time with my daughter, crafting, and reading.
                </p>
                <h2>Intrigued to learn more?</h2>
                 <PrimaryButton to="/contact">Get In Touch</PrimaryButton>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import PrimaryButton from "./PrimaryButton.vue";
export default {
    name: "About",
    components: {
        PrimaryButton,
    },
};
</script>

<style scoped>
h1 {
    margin-top: 1rem;
    margin-bottom: 1rem;
}
h2 {
    margin-top: 32px;
    margin-bottom: 32px;
}
.about-me-container {
    padding: 32px;
}
.about-me {
    text-align: left;
    margin-top: 32px;
    margin-bottom: 64px;
}

.image-column {
    padding-top: 64px;
    display: flex;
    flex-direction: row;
    justify-content: center;
}
</style>
